var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow pb-20"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-green-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("payement_details")) + " ")])])]), _c('div', {
    staticClass: "p-10",
    attrs: {
      "id": "detailP"
    }
  }, [_c('div', {
    staticClass: "flex justify-between mb-5 no-display"
  }, [_c('h2', {
    staticClass: "text-2xl font-bold mb-6 pb-2 tracking-wider uppercase"
  }), _c('div', {
    staticClass: "relative mr-4 inline-block"
  }, [_c('div', {
    staticClass: "text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center",
    on: {
      "click": _vm.openPopup
    }
  }, [_c('svg', {
    staticClass: "icon icon-tabler icon-tabler-printer",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0",
      "y": "0",
      "width": "24",
      "height": "24",
      "stroke": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"
    }
  }), _c('path', {
    attrs: {
      "d": "M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"
    }
  }), _c('rect', {
    attrs: {
      "x": "7",
      "y": "13",
      "width": "10",
      "height": "8",
      "rx": "2"
    }
  })])]), _c('div', {
    staticClass: "z-40 shadow-lg text-center w-32 block absolute right-0 top-0 p-2 mt-12 rounded-lg bg-gray-800 text-white text-xs",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "x-show.transition": "showTooltip"
    }
  }, [_vm._v(" Print this invoice! ")])])]), _c('div', {
    staticClass: "invoice"
  }, [_c('div', {
    staticClass: "invoice-title"
  }, [_vm._v(" Invoice "), _c('span', [_vm._v("#" + _vm._s(_vm.formData.id))])]), _vm._m(0)]), _c('section', {
    staticClass: "relative",
    attrs: {
      "id": "pdf-content"
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "flex space-x-3"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "w-full space-y-2"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("seller")) + " : "), _vm.formData.seller ? _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.seller.fullName || _vm.formData.seller.company) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "text-sm text-gray-600 capitalize"
  }, [_vm._v(" " + _vm._s(_vm.$t("warehouses")) + " : "), _vm.formData.warehouses && _vm.formData.warehouses.length > 0 ? _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, _vm._l(_vm.formData.country, function (war, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, war)) + " "), j != _vm.formData.country.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]);
  }), 0) : _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(_vm._s(_vm.formData.country ? _vm.$f.getCountryNameByCode(_vm.options, _vm.formData.country[0]) : ""))])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("payement_method")) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.method) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("date_payement")) + ": "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$moment(_vm.formData.date).format("yyyy-MM-DD")) + " ")])]), _vm.formData.intervalDate && _vm.formData.intervalDate.fromDate && _vm.formData.intervalDate.toDate ? _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("Periode")) + ": "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" [" + _vm._s(_vm.$moment(_vm.formData.intervalDate.fromDate).format("yyyy-MM-DD")) + " - " + _vm._s(_vm.$moment(_vm.formData.intervalDate.toDate).format("yyyy-MM-DD")) + "] ")])]) : _vm._e(), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" Number of orders: "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.count) + " ")])])])])])]), _vm._l(_vm.dataGroupedByCountry, function (group, index) {
    return _c('table', {
      key: index,
      staticClass: "w-full leading-normal mt-4",
      staticStyle: {
        "page-break-after": "avoid",
        "page-break-before": "avoid"
      }
    }, [_c('caption', [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, group.country) || _vm.$f.getCountryNameByCode(_vm.options, _vm.formData.country[0])) + " "), _c('small', [_vm._v("(" + _vm._s(group.count) + " orders)")])]), _c('thead', [_c('tr', [_c('th', {
      staticClass: "px-3 py-1 text-left border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" # ")]), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("order_id")) + " ")]), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" Product name ")]), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + " ")]), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("confirmation_fees")) + " ")]), _vm.formData.details[0] && _vm.formData.details[0].fulfillement_fees_vat ? _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("fulfillement_fees")) + " ")]) : _vm._e(), _vm.formData.country == 'MA' ? _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("city")) + " ")]) : _vm._e(), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("shipping_fees")) + " ")]), _c('th', {
      staticClass: "px-3 py-1 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-green-700 bg-green-200 capitalize tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("total")) + " ")])])]), _vm._l(group.orders, function (item, index) {
      return _c('tbody', {
        key: item._id
      }, [_c('tr', {
        class: index % 2 == 0 ? 'bg-gray-100' : ''
      }, [_c('td', {
        staticClass: "px-3 py-1 text-left border-b border-gray-200 bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(index + 1))])])]), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.order.id) + " ")])])]), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.products))])])]), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.shipping_refund_vat != 0 || item.shipping_cancel_vat != 0 ? 0 : item.order.totalPrice))])])]), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.confirmation_vat) + " ")])])]), _vm.formData.details[0] && _vm.formData.details[0].fulfillement_fees_vat != 0 ? _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.fulfillement_fees_vat) + " ")])])]) : _vm._e(), _vm.formData.country == 'MA' ? _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.order.customer.city) + " ")])])]) : _vm._e(), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.shipping_vat) + " ")])])]), _c('td', {
        staticClass: "px-2 py-1 border-b border-gray-200 text-center bg-white text-xs"
      }, [_c('p', {
        staticClass: "text-gray-700 whitespace-no-wrap"
      }, [_c('span', {
        staticClass: "font-semibold"
      }, [_vm._v(_vm._s(item.amount) + " ")])])])])]);
    }), _vm.dataGroupedByCountry.length > 1 ? _c('tbody', [_c('tr', [_vm._m(1, true), _c('td', {
      staticClass: "text-right text-sm text-gray-700 no-border",
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', [_vm._v(_vm._s(group.sales.toFixed(2)))]), _c('sup', [_vm._v(_vm._s(group.currency))])])]), _c('tr', [_vm._m(2, true), _c('td', {
      staticClass: "text-right text-sm text-gray-700 no-border",
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', [_vm._v(_vm._s(group.fees.toFixed(2)))]), _c('sup', [_vm._v(_vm._s(group.currency))])])]), _c('tr', [_vm._m(3, true), _c('td', {
      staticClass: "text-right text-sm text-gray-700 no-border",
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', [_vm._v(_vm._s(group.amount.toFixed(2)))]), _c('sup', [_vm._v(_vm._s(group.currency))])])]), _c('tr', [_c('td', {
      staticClass: "text-right text-sm text-gray-600 no-border",
      attrs: {
        "colspan": "6"
      }
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_c('svg', {
      staticClass: "fill-current float-right",
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "24",
        "height": "24",
        "viewBox": "0 0 24 24"
      }
    }, [_c('path', {
      attrs: {
        "d": "M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z"
      }
    })])])]), _c('td', {
      staticClass: "text-right text-sm text-gray-700 no-border",
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', [_vm._v(_vm._s((group.amount / _vm.formData.infoCurrency.find(function (o) {
      return o.currencyName === group.currency;
    }).currency).toFixed(2)))]), _c('sup', [_vm._v("MAD")])])])]) : _vm._e()], 2);
  }), _vm.expeditionCount > 0 ? _c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-gray-600 tracking-wider",
    attrs: {
      "colspan": 7
    }
  }, [_vm._v(" Expeditions" + _vm._s(_vm.$t("expeditions")) + " ")])])]), _c('thead', [_c('tr', [_c('th', {
    staticClass: "px-5 py-3 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("nb_packages")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("transport_mode")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("details")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("country")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 text-center border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("expedition_fees")) + " ")])])]), _c('tbody', {
    staticClass: "bg-white"
  }, _vm._l(_vm.formData.expeditions, function (exp, ind) {
    return _c('tr', {
      key: ind
    }, [_c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.id.numberOfPackages) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.id.transportMode) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.id.details.length) + " " + _vm._s(_vm.$t("products")) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.id.country) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 font-bold text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.fees) + " ")])])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "py-2 ml-auto mt-5 w-full sm:w-4/5",
    style: _vm.dataGroupedByCountry.length > 1 ? 'border-top: 2px solid black;' : ''
  }, [_c('div', {
    staticClass: "flex justify-between mb-3"
  }, [_c('div', {
    staticClass: "text-gray-800 text-right flex-1"
  }, [_vm._v("Total Sales:")]), _c('div', {
    staticClass: "text-right w-40"
  }, [_vm.formData.amountCurrency && _vm.formData.infoCurrency && _vm.formData.infoCurrency.length > 0 && _vm.formData.amountCurrency.length > 0 ? _c('div', {
    staticClass: "text-gray-800 font-medium"
  }, _vm._l(_vm.formData.amountCurrency, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.totalPrice.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrency.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "text-gray-800 font-medium"
  }, [_vm._v(" " + _vm._s(_vm.totalSales) + " "), _vm.idWharhouse ? _c('sup', [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])]), _c('div', {
    staticClass: "flex justify-between mb-3"
  }, [_c('div', {
    staticClass: "text-gray-800 text-right flex-1"
  }, [_vm._v("Total fees:")]), _c('div', {
    staticClass: "text-right w-40"
  }, [_vm.formData.amountCurrency && _vm.formData.infoCurrency && _vm.formData.infoCurrency.length > 0 && _vm.formData.amountCurrency.length > 0 ? _c('div', {
    staticClass: "text-gray-800 font-medium"
  }, _vm._l(_vm.formData.amountCurrency, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.fees.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrency.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "text-gray-800 font-medium"
  }, [_vm._v(" " + _vm._s(_vm.totalFees) + " "), _vm.idWharhouse ? _c('sup', [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])]), _vm.formData.vat ? _c('div', {
    staticClass: "flex justify-between mb-4"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600 text-right flex-1"
  }, [_vm._v(" TVA (" + _vm._s(_vm.formData.vat) + "%) ")]), _c('div', {
    staticClass: "text-right w-40"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.formData.vatFees) + " "), _vm.idWharhouse ? _c('sup', [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])]) : _vm._e(), _vm.formData.transfert_fees ? _c('div', {
    staticClass: "flex justify-between mb-4"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600 text-right flex-1"
  }, [_vm._v(" Transfert Fees ")]), _c('div', {
    staticClass: "text-right w-40"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.formData.transfert_fees) + " "), _vm.idWharhouse ? _c('sup', [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "py-2 border-t border-b"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "text-xl text-gray-600 text-right flex-1"
  }, [_vm._v(" Payment net: ")]), _vm.formData.amountCurrency && _vm.formData.infoCurrency && _vm.formData.infoCurrency.length > 0 && _vm.formData.amountCurrency.length > 0 ? _c('div', {
    staticClass: "text-right w-40"
  }, _vm._l(_vm.formData.amountCurrency, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.amount.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrency.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "text-right w-40"
  }, [_vm._v(" " + _vm._s(_vm.total.toFixed(2)) + " "), _vm.idWharhouse ? _c('sup', [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])]), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "text-xl text-gray-600 text-right flex-1"
  }, [_c('svg', {
    staticClass: "fill-current float-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z"
    }
  })])]), _vm.formData.amountCurrency && _vm.formData.infoCurrency && _vm.formData.infoCurrency.length > 0 && _vm.formData.amountCurrency.length > 0 ? _c('div', {
    staticClass: "text-right w-40"
  }, [_c('div', {
    staticClass: "text-xl text-gray-800 font-bold"
  }, [_vm._v(" " + _vm._s(_vm.formData.amountCurrency.map(function (item) {
    var objC = _vm.formData.infoCurrency.find(function (o) {
      return o.currencyName === item.currency;
    });
    return item.amount / objC.currency;
  }).reduce(function (prev, curr) {
    return prev + curr;
  }, 0).toFixed(2)) + " "), _c('sup', [_vm._v("MAD")])])]) : _c('div', {
    staticClass: "text-right w-40"
  }, [_vm.formData.info ? _c('div', {
    staticClass: "text-xl text-gray-800 font-bold"
  }, [_vm._v(" " + _vm._s((_vm.total / _vm.formData.info.currency).toFixed(2)) + " "), _c('sup', [_vm._v("MAD")])]) : _vm._e()])])])]), _c('vue-modal-2', {
    staticClass: "no-display",
    attrs: {
      "name": "nameInvoice",
      "headerOptions": {
        title: 'Payement Info'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": _vm.close
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/4 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-blue-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("file_download")])])]), _c('div', {
    staticClass: "w-full pr-4 mt-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-1"
  }, [_c('div', {
    staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "fullname"
    }
  }, [_vm._v("Invoice Name "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.InvoiceName,
      expression: "InvoiceName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.InvoiceName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.InvoiceName = $event.target.value;
      }
    }
  })])])])])]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Cancel")]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.generateReport
    }
  }, [_vm._v("Confirme")])])])])], 2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invoice-des"
  }, [_c('img', {
    staticClass: "align-middle border-none invoice-logo",
    attrs: {
      "alt": "cod",
      "src": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right text-sm text-gray-600 no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_c('span', {
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v("Sales")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right text-sm text-gray-600 no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_c('span', {
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v("Fees")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right text-sm text-gray-600 no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_c('span', {
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v("Amount")])]);

}]

export { render, staticRenderFns }